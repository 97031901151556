import { ComponentStyleProps, Icon, Modal, ThemeStore, Tile, Title } from 'aurora';
import { css, cx } from 'emotion';
import React, { useState } from 'react';

interface ProjectProps extends ComponentStyleProps {
  modalContent: JSX.Element;
  tags?: JSX.Element;
}
const Project: React.FC<ProjectProps> = (props) => {
  const themeStore = ThemeStore.useContainer();
  const colorTheme = themeStore.getColorTheme();
  const [hovered, setHovered] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const tileStyles = css`
    height: 250px;
    width: 350px;
    border-radius: 12px;
    margin-right: 32px;
    margin-bottom: 32px;
    background-color: ${colorTheme.light.base()};
    overflow: hidden;

    position: relative;
    cursor: pointer;
  `;

  const iconStyles = css`
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 16px;
  `;

  const tagContainerStyles = css`
    width: 100%;
    height: 50px;
    opacity: 0.8;

    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const modalStyles = css`
    height: 800px;
    width: 600px;
  `;

  return (
    <React.Fragment>
      <Tile
      styles={cx(tileStyles, props.styles)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => setModalOpened(true)}
      position='center'
      alignment='center'
    >
        {
          hovered &&
          <React.Fragment>
            <Icon
              icon='fad fa-clone'
              styles={iconStyles}
              variant='gray'
              isInteractable
              onClick={() => setModalOpened(false)}
            />
          </React.Fragment>
        }
        {props.children}
        <div className={tagContainerStyles}>
          {props.tags}
        </div>
      </Tile>
      <Modal active={modalOpened} onClose={() => setModalOpened(false)} styles={modalStyles}>
        {props.modalContent}
      </Modal>
    </React.Fragment>
  );
};

export {
  Project
};
