import { Icon, Text } from 'aurora';
import { css } from 'emotion';
import React from 'react';

import { Tag } from '~Components/Tags/Tag';

const ReactTag: React.FC = () => {
  const colorStyles = css`
    background-color: #61dafb;
  `;

  const textStyles = css`
    margin-left: 4px;
  `;

  return (
    <Tag styles={colorStyles}>
      <Icon icon='fab fa-react' styles={colorStyles}/>
      <Text styles={textStyles} size='small'>React</Text>
    </Tag>
  );
};

export {
  ReactTag
};
