import { Container, Title } from 'aurora';
import { css } from 'emotion';
import React from 'react';

const AuroraImg: React.FC = () => {
  const imgStyles = css`
    height: 100%;
    width: 100%;
    background-color: #262666;
  `;
  const imgTitleStyles = css`
    color: #e5b657;
  `;

  return (
    <Container alignment='center' position='center' styles={imgStyles}>
      <Title size='extraLarge' styles={imgTitleStyles}>Aurora</Title>
    </Container>
  );
};

export {
  AuroraImg
};
