import { Container, DropdownDivider, Icon, Subtitle, Text, ThemeStore } from 'aurora';
import { css } from 'emotion';
import { motion, Variants } from 'framer-motion';
import React from 'react';

import { AuroraProject } from '~Views/Homepage/Projects/Aurora/AuroraProject';

const HomepageBody: React.FC = () => {
  const themeStore = ThemeStore.useContainer();
  const colorTheme = themeStore.getColorTheme();

  const bodyStyles = css`
    padding: 24px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  `;

  const contentContainerStyles = css`
    width: calc(100% + 48px);
    height: 30%;
    margin-left: -24px;
    margin-bottom: 24px;
  `;
  const bodyContainerStyles = css`
    padding-top: 20px;
    width: 100%;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    box-sizing: border-box;
    overflow-y: auto;
  `;

  const variants: Variants = {
    hidden: {
      y: 0,
      opacity: 0
    },
    visible: {
      y: 20,
      opacity: .9
    }
  };

  const descriptionStyles = css`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  `;

  const subtitleStyles = css`
    margin-left: 20px;
  `;

  const circleIconStyles = css`
    font-size: 4px;
    margin-left: 8px;
    margin-right: 8px;
    color: #11998e;
    display: flex;
    align-self: center;
  `;

  const dividerStyles = css`
    border-bottom: 1px solid ${colorTheme.gray.base()};
  `;

  return (
    <Container styles={bodyStyles} direction='column'>
      <Container styles={contentContainerStyles} alignment='center' position='center'>
        <motion.div
          variants={variants}
          initial='hidden'
          animate='visible'
          transition={{
            duration: 1
          }}
        >
          <Container direction='column'>
            <Text size='medium' styles={descriptionStyles} variant='primary'>Hi, it's Isaiah.</Text>
            <Text size='medium' styles={descriptionStyles} variant='primary'>
              I am a software engineer based in Minneapolis, Minnesota.
            </Text>

            <Container>
              <Subtitle size='normal' styles={subtitleStyles} variant='primary'>developer of technology</Subtitle>
              <Icon icon='fas fa-circle' styles={circleIconStyles}/>
              <Subtitle size='normal' variant='primary'>esports enthusiast</Subtitle>
              <Icon icon='fas fa-circle' styles={circleIconStyles}/>
              <Subtitle size='normal' variant='primary'>life long learner</Subtitle>
            </Container>
            <DropdownDivider styles={dividerStyles}/>
          </Container>
        </motion.div>
      </Container>
      <Container styles={bodyContainerStyles} position='center' direction='row wrap'>
        <AuroraProject/>
      </Container>
    </Container>
  );
};

export {
  HomepageBody
};
