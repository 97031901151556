import { Container, Subtitle, Text, Title } from 'aurora';
import { css } from 'emotion';
import React from 'react';

interface ProjectModalProps {
  title: string;
  subtitle?: string;
  description: string;
  img: JSX.Element;
  tags?: JSX.Element[];
  links?: JSX.Element;
}
const ProjectModal: React.FC<ProjectModalProps> = (props) => {
  const containerStyles = css`
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
  `;

  const imgContainerStyles = css`
    height: 300px;
    width: 100%;
    margin-bottom: 40px;
    border-radius: 12px;
    overflow: hidden;
  `;

  const tagContainerStyles = css`
    margin-bottom: 10px;
  `;

  const titleStyles = css`
    margin: 0;
  `;

  return (
    <Container styles={containerStyles} direction='column'>
      <Container styles={imgContainerStyles}>
        {props.img}
      </Container>
      <Container position='space-between'>
        <Container direction='column'>
          <Title styles={titleStyles}>{props.title}</Title>
          {props.subtitle && <Subtitle variant='dark'>{props.subtitle}</Subtitle>}
          <Container styles={tagContainerStyles}>
            {props.tags}
          </Container>
        </Container>
        <Container>
          {props.links}
        </Container>
      </Container>
      <Text tag='p' variant='dark'>{props.description}</Text>
    </Container>
  );
};

export {
  ProjectModal
};
