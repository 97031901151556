import { Container, Icon, Text, Title } from 'aurora';
import { css } from 'emotion';
import React from 'react';

const NotFoundView: React.FC = () => {
  const containerStyles = css`
    height: 100%;
    width: 100%;
  `;

  const iconStyles = css`
    font-size: 56px;
    margin-bottom: 20px;
  `;

  const titleStyles = css`
    font-family: 'Source Sans Pro';
  `;

  return (
    <Container styles={containerStyles} alignment='center' position='center' direction='column'>
      <Icon icon ='fad fa-space-station-moon-alt' styles={iconStyles}/>
      <Container direction='column'>
        <Title styles={titleStyles} size='large' variant='primary'>
          Hey! We're not quite ready yet.
        </Title>
        <Text styles={titleStyles} size='large' variant='primary'>
          Check again later when we've stabalized.
        </Text>
      </Container>
    </Container>
  );
};

export {
  NotFoundView
};
