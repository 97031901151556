// tslint:disable:file-name-casing
import { Aurora } from 'aurora';
import React from 'react';
import DOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ApplicationView } from '~Views/Application/ApplicationView';

async function init() {
  DOM.render((
    <Aurora
      fontTheme={{
        fontFamily: 'Open Sans',
        extraLarge: '32px'
      }}
      colorTheme={{
        primary: {
          base: () => '#111D42'
        },
        secondary: {
          base: () => '#61D095'
        }
      }}
    >
      <BrowserRouter>
        <ApplicationView/>
      </BrowserRouter>
    </Aurora>
  ), document.getElementById('app'));
}

init();
