import { Icon, Navbar, NavbarBrand, NavbarContent, NavbarEnd, ThemeStore } from 'aurora';
import { css } from 'emotion';
import React from 'react';

const logo = require('../../Resources/logo-light.png');

const HomepageHeader: React.FC = () => {
  const themeStore = ThemeStore.useContainer();
  const colorTheme = themeStore.getColorTheme();

  const navbarStyles = css`
    height: 100px;
    width: 100%;
    box-shadow: none;
  `;

  const socialIconStyles = css`
    font-size: 20px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 4px;
    background-clip: text;
    -webkit-background-clip: text;

    :hover {
      cursor: pointer;
      color: transparent;
      background-image: linear-gradient(to bottom right,#38ef7d, #11998e);
    }
  `;

  const logoStyles = css`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    cursor: pointer;
  `;

  const logoLinkStyles = css`
    text-decoration: none;
    display: flex;
    align-items: center;

    &:focus {
      color: ${colorTheme.primary.base()};
    }
  `;

  return (
    <Navbar styles={navbarStyles}>
      <NavbarBrand>
        <a href='/' className={logoLinkStyles}>
          <img src={logo} className={logoStyles}/>
        </a>
      </NavbarBrand>
      <NavbarContent position='center'>
      </NavbarContent>
      <NavbarEnd>
        <a href='https://github.com/isoung'>
          <Icon icon='fab fa-github' styles={socialIconStyles}/>
        </a>
        <a href='https://www.linkedin.com/in/isaiahsoung/'>
          <Icon icon='fab fa-linkedin' styles={socialIconStyles}/>
        </a>
        <a href='https://www.instagram.com/isphotograph/'>
          <Icon icon='fab fa-instagram' styles={socialIconStyles}/>
        </a>
      </NavbarEnd>
    </Navbar>
  );
};

export {
  HomepageHeader
};
