import { ComponentProps, Tag as AuroraTag } from 'aurora';
import { css, cx } from 'emotion';
import React from 'react';

const Tag: React.FC<ComponentProps> = (props) => {
  const tagStyles = css`
    margin: 4px;
  `;

  return (
    <AuroraTag {...props} styles={cx(tagStyles, props.styles)}>
      {props.children}
    </AuroraTag>
  );
};

export {
  Tag
};
