import { Container } from 'aurora';
import { css } from 'emotion';
import React from 'react';

import { HomepageBody } from '~Views/Homepage/HomepageBody';
import { HomepageHeader } from '~Views/Homepage/HomepageHeader';

const HomepageView: React.FC = () => {
  const containerStyles = css`
    height: 100%;
    width: 100%;
  `;

  return (
    <Container styles={containerStyles} direction='column'>
      <HomepageHeader/>
      <HomepageBody/>
    </Container>
  );
};

export {
  HomepageView
};
