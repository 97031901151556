import React from 'react';

import { Tag } from '~Components/Tags/Tag';

const WebTag: React.FC = () => {
  return (
    <Tag variant='secondary'>Web</Tag>
  );
};

export {
  WebTag
};
