import { Button } from 'aurora';
import { css } from 'emotion';
import React from 'react';

import { Project } from '~Components/Project/Project';
import { ProjectModal } from '~Components/Project/ProjectModal';
import { ReactTag } from '~Components/Tags/ReactTag';
import { WebTag } from '~Components/Tags/WebTag';
import { AuroraImg } from '~Views/Homepage/Projects/Aurora/AuroraImg';

const links: JSX.Element =
  <div>
    <a href='https://github.com/isoung/aurora'>
      <Button variant='light'>View on Github</Button>
    </a>
  </div>;

const AuroraProject: React.FC = () => {
  const auroraProjectStyles = css`
    background: #262666;
  `;

  return (
    <Project styles={auroraProjectStyles}
      tags={
        <React.Fragment>
          <WebTag/>
          <ReactTag/>
        </React.Fragment>
      }
      modalContent={
        <ProjectModal
          title='Aurora'
          subtitle='A React component library'
          tags={[<WebTag/>, <ReactTag/>]}
          description='Aurora is a react component library that aims to allow for quick React app development'
          img={<AuroraImg/>}
          links={links}
        />
      }
    >
      <AuroraImg/>
    </Project>
  );
};

export {
  AuroraProject
};
