import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFoundView } from '~Views/404/404View';
import { HomepageView } from '~Views/Homepage/HomepageView';

const ApplicationView: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/' component={HomepageView}/>
      <Route path='/404' component={NotFoundView}/>
      <Redirect to='/404'/>
    </Switch>
  );
};

export {
  ApplicationView
};
